<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Trade Agreement</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="tradeAgreementName">Name <span class="red-dot ml-3"></span></label>
        <input id="tradeAgreementName" name="tradeAgreementName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('tradeAgreementName') }"
          autocomplete="off" placeholder="Name" v-model="tradeAgreement.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>
      <div class="form-group">
        <label for="tradeAgreementCode">Code <span class="red-dot ml-3"></span></label>
        <input id="tradeAgreementCode" name="tradeAgreementCode" type="text" class="form-control" :class="{ 'is-invalid': errors.has('tradeAgreementCode') }"
          autocomplete="off" placeholder="Code" v-model="tradeAgreement.code" v-validate="'required'" data-vv-as="Code" />
        <div class="invalid-feedback">The Code field is required.</div>
      </div>
      <div class="form-group">
        <label for="tradeAgreementSortOrder">Sort Order <span class="red-dot ml-3"></span></label>
        <input id="tradeAgreementSortOrder" name="tradeAgreementSortOrder" type="text" class="form-control" :class="{ 'is-invalid': errors.has('tradeAgreementSortOrder') }"
          autocomplete="off" placeholder="Sort Order" v-model="tradeAgreement.sortOrder" v-validate="'required'" data-vv-as="Sort Order" />
        <div class="invalid-feedback">The Sort Order field is required.</div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'contact-type',
  data: function () {
    return {
      isEdit: false,
      tradeAgreement: {}
    }
  },
  computed: {
    ...mapGetters(["getTradeAgreement"])
  },
  mounted: function() {
    this.tradeAgreement = { ...this.getTradeAgreement };
    this.isEdit = !!this.tradeAgreement.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveTradeAgreement({ data: this.tradeAgreement, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-trade-agreement");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-trade-agreement");
    },
    ...mapActions(["saveTradeAgreement"])
  }
}
</script>
